import {useState} from 'react';
// eslint-disable-next-line
import FirebaseAuthService from './FirebaseAuthService';
// eslint-disable-next-line
import LoginForm from './components/LoginForm';
import './App.css';

function App() {
  const[user,setUser]=useState(null);
  FirebaseAuthService.subscribeToAuthChanges(setUser);
  return (
    <div className="App">
      <div className="title-row">
        <h1 className='title'>Portfolio</h1>
      <LoginForm existingUser={user}></LoginForm>
    </div>
    </div>
  );
}

export default App;